// Wait for the DOM to be ready before executing the script
document.addEventListener('DOMContentLoaded', function () {
    // Get all h2 elements
    const h2Elements = document.querySelectorAll('.text-container h2');

    // Add click event listeners to each h2 element
    h2Elements.forEach(h2 => {
        // Toggle the visibility of the next sibling element (the content) initially
        const content = h2.nextElementSibling;
        content.style.display = 'none';

        h2.addEventListener('click', () => {
            // Toggle the visibility of the next sibling element (the content) on click
            content.style.display = (content.style.display === 'none' || content.style.display === '') ? 'block' : 'none';
        });
    });
});